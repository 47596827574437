
@import url('https://fonts.googleapis.com/css2?family=Rampart+One&display=swap');

body{
  
}
  #Resume{
    h1{font-size: 3vw;
        padding: 20px;
        text-align: center;
    }
   

  }
@media screen and (max-width: 1000px) 
{
    #Resume{
        h1{
            font-size: 4vh;
            text-align: center;
            font-family: 'Rampart One', cursive;
            color:darkgoldenrod;
        }
    }
    #resume_img{
        width: 37vh;
  }
}
@media screen and (min-width: 1000px) {
  #Resume{
    h1{
      
        text-align: center;
        font-family: 'Rampart One', cursive;
        color:darkgoldenrod;
    }}
    #resume_img{
        width: 70vh;
  }
  
}
  .box
{
 
  margin: 0 auto;
  padding: 2px;
  background-color: #eaab00; 
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAOqrAP///yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='),  url('data:image/gif;base64,R0lGODlhAQABAPAAAOqrAP///yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='),
url('data:image/gif;base64,R0lGODlhAQABAPAAAP///////yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==');
  background-repeat: no-repeat;
  background-size: 0 2px, 0 100%, 0% 2px;
  background-position: top center, top center, bottom center;
  -webkit-animation: drawBorderFromCenter 4s;
}


@-webkit-keyframes drawBorderFromCenter {
    0% {
      background-size: 0 2px, 0 0, 100% 100%;
    }
    20% {
      background-size: 100% 2px, 100% 0, 100% 100%;
    }
    66%
    {
      background-size: 100% 2px, 100% 98%, 100% 100%;
    }
    99%
    {
      background-size: 100% 2px, 100% 98%, 0 2px;
    }
}



.content
{
  background: white;
  padding: 2em;
  text-align: center;
  text-transform: uppercase;
}



/* CSS */
.button-35 {
  align-items: center;
  background-image: linear-gradient(to bottom, #fff, #e2d3b5);
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter,sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s,-webkit-box-shadow .2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

}

.button-35:hover {
  box-shadow: #e7c66d 0 0 0 3px, transparent 0 0 0 0;
}


.p{
  text-decoration: none;
}
@media screen and (max-width:1000px){
  table{
    border-spacing:1vh;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width:1000px) {
  table{
    border-spacing:7vh ;
    margin-left: auto;
    margin-right: auto;
  }
}
#pdf{
  width:70vh;
  height:70vh;
}